.durationsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
}

.inputEndContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.inputCoinName {
  font-weight: 300;
  color: var(--gray-color);
}
.divider {
  height: 20px;
  width: 1px;
  background-color: var(--border-color);
  border-radius: 2px;
}

.summaryNumbersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.summaryNumbersContainer > *:first-child {
  color: var(--gray-color);
}
.summaryNumbersContainer > *:last-child {
  color: var(--primary-color);
}

.summaryText {
  color: var(--gray-color);
  font-size: 10px;
  margin: 10px 0 20px 0;
}
