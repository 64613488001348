.sideNavButton {
    border-radius: 5px;
    padding: 10px 14px;
    width: fit-content;
    background: none;
    color: #6C7080;
    font-size: 14px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
}
.sideNavButton:hover {
    background-color: black;
    color: var(--primary-color);
    transition: all .3s ease;
}

/* text */
.sideNavButton span {
    margin: 0 10px 0 20px;
}

.selected {
    color: var(--primary-color);
    background-color: black;
    /* border: 1.5px var(--primary-color) solid; */
}
.selected:focus {
    /* border: 1.5px var(--primary-color) solid; */
}


/* LIGHT MODE */
.lightButton:hover {
    background-color: var(--primary-color);
    color: #051A1F;
    transition: all .3s ease;
}

.selectedLight {
    background-color: var(--primary-color);
    color: #051A1F;
}