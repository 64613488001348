.button {
  /* background-color: black; */
  background-color: #121318;
  border: none;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 11px;
  border-radius: 2px;
  padding: 3.5px 8px;
}
.button:hover {
  background-color: var(--primary-color);
  color: black !important;
}
.button:disabled {
  background-color: var(--gray-color);
  color: var(--text-color);
  cursor: not-allowed;
}
