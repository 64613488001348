.card {
  background-color: black;
  border-radius: 3.75px;
  padding: 5px;
  /* flex: 1; */
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
  max-width: 120px;
  padding-bottom: 12.5px;
}

.lightCard {
  background-color: white;
}

.header {
  color: #6c7080;
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-bottom: auto;
  white-space: nowrap;
}
.header *:last-child {
  margin-right: 5px;
}

.price {
  font-size: 11px;
  /* margin:apx 0 0 0; */
  margin-bottom: -5px;
}

.coinChange {
  display: flex;
  align-items: center;
  font-size: 11px;
}
.coinChange img {
  width: 12px;
  margin-left: 4px;
}
